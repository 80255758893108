<template>
  <div class="modal modal-center" id="importCsv">
    <div class="modal-bg" @click="$emit('close')"></div>
    <div class="modal-block">
      <div class="modal-body">
        <div class="modal-title">Upload Bank rate CSV</div>
        <file-field  class="mt-3" :field-id="'fieldId'" :files="files" :multiple="true" @change="onFileChange" />
        <div class="d-flex mt-4">
          <button class="btn bg-green" :disabled="files.length === 0 || ui.saveLoading" @click="uploadFile">
             <span v-if="ui.saveLoading === true"
                   class="spinner-border spinner-border-sm"
                   role="status" aria-hidden="true">
            </span>
            <span v-if="ui.saveLoading === false">Upload</span>
          </button>
          <button class="btn btn-cancel ms-2 px-5" @click="$emit('close')">
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FileField from "../../../components/form/FileField";
export default {
  name: 'UploadBankRateCsv',
  components: {FileField},
  data() {
    return {
      ui: {
        saveLoading: false
      },
      files: [],
      formValue: new FormData()
    }
  },
  methods: {
    handleFile() {
      this.file = this.$refs.chase.files[0]
    },
    onFileChange(e) {
      let length = e.target.files.length
      for(let i = 0; i < length; i++) {
        this.files.push(e.target.files[i])
        this.formValue.append('files[]', e.target.files[i])
      }
    },
    closeModal() {
      this.$emit('close')
    },
    uploadFile() {
      this.ui.saveLoading = true
      this.$http.post('/api/v1/bank-rate-tool/upload-bank-rate-csv', this.formValue)
        .then(() => {
          this.pushAlert('success', 'BankRate CSV file handled successfully')
          let parent = this.$parent
          if (parent.$options.name === 'BankrateBillingTool') {
            parent.getData()
          } else {
            parent.getUnassignedLeads()
            parent.setActiveTab('unassigned')
          }
          console.log(this.$parent)
          this.closeModal()
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }
}
</script>

<style lang="scss">
#importCsv {
  .modal-block {
    height: 206px;
    max-width: 384px;
    width: 100%;
    border: 0.0625rem solid rgba(239,239,239,1);
    border-radius: 0.5rem;
    background-color: rgba(255,255,255,1);
    box-shadow: 0 0.25rem 0.75rem 0 rgba(0,0,0,0.07);
    margin: 100px auto;
    overflow: hidden;
  }
  .modal-body {
    padding: 30px;
    .modal-title {
      color: rgba(0,0,0,1);
      font-size: 1rem;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 1.5rem;
    }
  }
  button {
    width: 50%;
  }
}

</style>
