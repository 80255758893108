<template>
  <div class="modal modal-center">
    <div class="modal-bg" @click="$emit('close')"></div>
    <div class="modal-block">
      <div class="modal-header">
        <h3>
          Select Agent
        </h3>
      </div>
      <div class="modal-body">
        <div class="modal-body-container border-bottom-0">
          <div class="form-group">
            <label for="fieldAgent">Agent</label>
            <multiselect placeholder="Select Agent" id="fieldAgent" track-by="id" label="fullName"
                         v-model="agent"
                         :allowEmpty="false"
                         :options="agents"
                         :class="{selected: Object.keys(agent).length > 0}"
                         :preselect-first="true"
                         :show-labels="false">
            </multiselect>
          </div>
        </div>
      </div>
      <div class="modal-footer justify-content-start">
        <div class="d-flex">
          <button class="btn bg-green">
            Save
          </button>
          <button class="btn btn-cancel ms-2">
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';

export default {
  name: 'BankrateChangeAgent',
  components: { Multiselect },
  data() {
    return {
      agent: '',
      agents: []
    }
  }
}
</script>

<style lang="scss" scoped>
.modal {
  .modal-block {
    height: 35%;
    margin: 100px auto;
    .modal-footer {
      bottom: unset;
    }
  }
}
.modal-body-container::v-deep .multiselect {
  &.selected {
    .multiselect__tags {
      background-color: #F3F3F4;
    }

    .multiselect__single {
      background-color: #F3F3F4;
      color: #000000;
      font-size: 14px;
      letter-spacing: 0;
    }

    input {
      background-color: #F3F3F4;
    }
  }

  &.multiselect--active {
    .multiselect__tags {
      transition: all .3s;
      border: 1px solid rgba(38, 111, 229, 0.5);
      box-shadow: 0 0 0 4px rgba(38, 111, 229, 0.1);
    }
  }

  &__tags {
    padding: 10px 40px 0 15px;
    transition: all .3s;

    &:hover {
      border: 1px solid rgba(38, 111, 229, 0.2);
      box-shadow: 0 0 0 4px rgba(38, 111, 229, 0.1);
    }
  }

  &__select {
    &:before {
      top: 17%;
      position: relative;
      right: 0;
      color: #999;
      border: solid rgba(0, 0, 0, 0.46);
      margin-top: 4px;
      border-width: 0px 1px 1px 0;
      display: inline-block;
      padding: 3px;
      content: "";
      transform: rotate(44deg);
    }
  }

  &__single {
    color: rgba(0, 0, 0, 0.46);
    @media screen and (max-width: 568px) {
      font-size: 9px;
    }
  }

  &__option {
    padding: 10px 12px;
    min-height: 36px;
    transition: all .3s;
    line-height: 20px;
    font-size: 14px;
    color: #000;
    @media screen and (max-width: 568px) {
      font-size: 9px;
    }

    &--highlight {
      background: #f3f3f3;
      line-height: 20px;
      font-size: 14px;
      color: #000;
      @media screen and (max-width: 568px) {
        font-size: 9px;
      }
    }
  }
}
</style>
