<template>
  <div class="modal">
    <div class="modal-bg" @click="$emit('close')"></div>
    <div class="modal-block">
      <div class="modal-header">
        <h3>Bankrate: {{ agent.fullName }}</h3>
      </div>
      <div class="modal-body">
        <div class="text-center mt-5" v-if="ui.loading === true">
          <div class="spinner-border mt-5" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
        <div v-else>
          <div class="modal-body-container">
            <div class="row g-3">
              <div class="col-md-6">
                <label for="fieldTotalPrice">Total Price</label>
                <input type="text" class="form-control disabled" id="fieldTotalPrice" :value="convertToCurrency(totalPrice)" disabled>
              </div>
              <div class="col-md-6">
                <label for="fieldMargin">Margin ($ per lead):</label>
                <input type="number" class="form-control" step="0.5" id="fieldMargin" v-model="margin" @change="calcTotalPrice">
              </div>
            </div>
          </div>
          <div class="modal-body-container border-bottom-0">
            <div class="table">
              <table class="table">
                <thead>
                <tr>
                  <th>
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" v-model="checkAll" @click="toggleCheckAll">
                    </div>
                  </th>
                  <th>Address</th>
                  <th>Date</th>
                  <th>Loan amount</th>
                  <th>Price</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(el, index) in agent.leads" :key="index">
                  <td>
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" v-model="el.isChecked" @click="el.isChecked = !el.isChecked; calcTotalPrice()">
                    </div>
                  </td>
                  <td>{{ el.address }}</td>
                  <td>{{ el.eventDate }}</td>
                  <td>{{ convertToCurrency(el.loanAmount) }}</td>
                  <td>{{ convertToCurrency(el.cost) }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer d-flex justify-content-start" v-if="ui.loading === false">
        <button class="btn bg-green" @click="charge" :disabled="!ui.charge">
           <span v-if="ui.saving === true" class="spinner-border spinner-border-sm text-light"
                 role="status" aria-hidden="true">
          </span>
          <span class="text-end" v-else>
            Charge
          </span>
        </button>
        <button class="btn btn-cancel" @click="$emit('close')">
          Cancel
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BankRateChargeModal",
  data() {
    return {
      totalPrice: 0.00,
      checkAll: true,
      margin: 0.00,
      ui: {
        loading: false,
        saving: false,
        charge: false
      }
    }
  },
  props: {
    agent: { required: true, type: Object }
  },
  methods: {
    calcTotalPrice() {
      let margin = this.margin;
      let totalPrice = .0;
      if (!margin) { margin = 0; }

      this.agent.leads.map((el) => { if (el.isChecked === true) {
        totalPrice += Number.parseFloat(el.cost) + Number(margin);
      } });

      this.totalPrice = totalPrice;
      this.ui.charge = this.leadsSelected()
    },
    toggleCheckAll() {
      this.checkAll = !this.checkAll;
      this.agent.leads.map((el) => { el.isChecked = this.checkAll !== false });
      this.calcTotalPrice();
    },
    leadsSelected() {
      let total = 0;
      this.agent.leads.map((el) => { if (el.isChecked === true) total++ })
      return total > 0
    },
    charge() {
      this.ui.saving = true;
      let formData = new FormData();
      formData.append('margin', this.margin)
      this.agent.leads.map((el) => { if (el.isChecked === true) formData.append('leads[]', el.id) })

      this.$http.post(`/api/v1/bank-rate-tool/charge-account/${this.agent.id}`, formData)
        .then(() => {
          this.ui.saving = false;
          this.$parent.getAgents();
          this.pushAlert('success', 'Expense successfully submitted! Please wait a moment..')
          this.$emit('close');
        })
        .catch()
    }
  },
  created() {
    this.ui.loading = true;
    this.agent.leads.map((el) => { el.isChecked = true });
    this.calcTotalPrice();
    this.ui.loading = false;
  }
}
</script>

<style lang="scss" scoped>
.form-check {
  padding: 0;
  margin: 0;
  input {
    width: 18px!important;
    height: 18px;
    padding: 0;
    margin: 0;
  }
}
div.table {
  table {
    thead {
      tr {
        th {
          &:first-child {
            padding-left: 15px;
          }
        }
      }
    }
  }
}
</style>

