<template>
  <div>
    <!--  Page Header  -->
    <div class="page-header d-flex align-items-center">
      <div class="row h-100 w-100">
        <div class="col-12 col-md-6 col-lg-7 mt-2 my-md-auto">
          <h3 class="page-header-title">BankRate Lead Tool</h3>
        </div>
        <div class="col-12 col-md-6 col-lg-5">
          <div class="d-flex justify-content-end">
            <button class="btn bg-green" @click="modals.bankRateUpload = true">
              Upload Bank rate CSV
            </button>
            <button class="btn btn-primary ms-3" @click="modals.leadMailboxUpload = true">
              Upload LeadMailBox CSV
            </button>
          </div>
        </div>
      </div>
    </div>

    <!--  Page tabs  -->
    <div class="page-tabs d-flex justify-content-between">
      <ul class="tab-list">
        <li :class="{ 'active': isActive('unassigned') }" @click="setActiveTab('unassigned')">
          Unassigned <span v-if="unassignedCount">({{ unassignedCount }})</span>
        </li>
        <li :class="{ 'active': isActive('assigned') }" @click="setActiveTab('assigned')">
          Assigned <span v-if="assignedCount">({{ assignedCount }})</span>
        </li>
        <li :class="{ 'active': isActive('agents') }" @click="setActiveTab('agents')">
          Agents
        </li>
      </ul>
    </div>

    <div class="page-content p-normal">
      <div class="text-center mt-5" v-if="ui.loading === true">
        <div class="spinner-border mt-5" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
      <div v-else>
        <div class="table" v-if="isActive('unassigned') || isActive('assigned')">
          <table class="table">
            <thead>
            <tr>
              <th>Unique ID</th>
              <th v-if="isActive('assigned')">Agent</th>
              <th>Event date</th>
              <th>Address</th>
              <th>Loan amount</th>
              <th>Cost</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="lead in leads" :key="lead.leadId">
              <td>{{ lead.leadId }}</td>
              <td v-if="isActive('assigned')">{{ lead.agent.fullName }}</td>
              <td>{{ lead.eventDate }}</td>
              <td>{{ lead.address }}</td>
              <td>{{ convertToCurrency(lead.loanAmount) }}</td>
              <td>{{ convertToCurrency(lead.cost) }}</td>
              <td align="right">
                <button class="btn btn-primary me-2" @click="deleteLead(lead.id)">
                  Delete
                </button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="table" v-if="isActive('agents')">
          <table class="table">
            <thead>
            <tr>
              <th>Agent</th>
              <th>NMLS</th>
              <th>Last updated on</th>
              <th>Last charged</th>
              <th>Total Due</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(agent, agentIdx) in agents" :key="agentIdx">
              <td>{{ agent.fullName }}</td>
              <td>{{ agent.nmls }}</td>
              <td>{{ agent.lastUpdated ? formatDate(agent.lastUpdated) : 'none' }}</td>
              <td>{{ convertToCurrency(agent.lastCharged) }}</td>
              <td>{{ convertToCurrency(agent.charges) }}</td>
              <td>
                <button class="btn btn-primary me-2" :disabled="agent.charges === 0" @click="chargeAgent(agent)">
                  Charge
                </button>
                <button class="btn btn-secondary" :disabled="agent.charges === 0" @click="chargeAll(agent)">
                  Quick charge
                </button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <transition name="component-fade" mode="out-in">
      <upload-bank-rate-csv v-if="modals.bankRateUpload" @close="modals.bankRateUpload = false"/>
    </transition>
    <transition name="component-fade" mode="out-in">
      <upload-lead-mailbox-csv v-if="modals.leadMailboxUpload" @close="modals.leadMailboxUpload = false"/>
    </transition>
    <transition name="component-fade" mode="out-in">
      <bankRate-charge-modal v-if="modals.charge"
                             :agent="selectedAgent"
                             @close="modals.charge = false"/>
    </transition>
    <transition name="component-fade" mode="out-in">
      <bankRate-change-agent v-if="modals.changeAgent" @close="modals.changeAgent"/>
    </transition>
  </div>
</template>

<script>
import UploadBankRateCsv from "./modals/UploadBankrateCsv";
import UploadLeadMailboxCsv from "./modals/UploadLeadMailBoxCSV";
import BankRateChargeModal from "./modals/BankrateChargeModal";

import BankRateChangeAgent from "./modals/BankrateChangeAgent";

export default {
  name: 'BankRateLeadTool',
  components: {UploadBankRateCsv, UploadLeadMailboxCsv, BankRateChargeModal, BankRateChangeAgent},
  data() {
    return {
      ui: {
        tabName: 'unassigned',
        loading: false
      },
      modals: {
        bankRateUpload: false,
        leadMailboxUpload: false,
        charge: false,
        changeAgent: false
      },
      leads: [],
      agents: [],
      selectedAgent: {},
      unassignedCount: 0,
      assignedCount: 0,
    }
  },
  beforeMount() {
    this.getCounts()
    this.getUnassignedLeads()
  },
  methods: {
    getData() {
      this.ui.loading = true

      setTimeout(() => {
        this.ui.loading = false
      }, 1000)
    },
    isActive(val) {
      return this.ui.tabName === val
    },
    setActiveTab(val) {
      this.ui.loading = true
      this.ui.tabName = val

      if (this.ui.tabName === 'unassigned') { this.getUnassignedLeads() }
      if (this.ui.tabName === 'assigned') { this.getAssignedLeads() }
      if (this.ui.tabName === 'agents') { this.getAgents() }
    },
    getCounts() {
      this.loading = false
      this.$http.get('/api/v1/bank-rate-tool/get-counts')
        .then((res) => {
          this.unassignedCount = res.data.unassigned
          this.assignedCount = res.data.assigned
        })
        .catch()
    },
    getUnassignedLeads() {
      this.$http.get('/api/v1/bank-rate-tool/unassigned')
        .then((res) => {
          this.leads = res.data.leads
          this.unassignedCount = this.leads.length
          this.ui.loading = false
        })
        .catch()
    },
    getAssignedLeads() {
      this.loading = false
      this.$http.get('/api/v1/bank-rate-tool/assigned')
        .then((res) => {
          this.leads = res.data.leads
          this.assignedCount = this.leads.length
          this.ui.loading = false
        })
        .catch()
    },
    getAgents() {
      this.$http.get('/api/v1/bank-rate-tool/get-agents')
        .then((res) => {
          this.agents = res.data.agents
          this.ui.loading = false
        })
        .catch()
    },
    chargeAgent(agent) {
      this.selectedAgent = agent
      this.modals.charge = true
    },
    chargeAll(agent) {
      if (confirm('Are you sure?')) {
        this.ui.loading = true;
        let formData = new FormData();
        formData.append('margin', '0.00')
        agent.leads.map((el) => { formData.append('leads[]', el.id) })

        this.$http.post(`/api/v1/bank-rate-tool/charge-account/${agent.id}`, formData)
          .then(() => {
            this.ui.loading = false;
            this.getAgents();
            this.pushAlert('success', 'Expense successfully submitted! Please wait a moment..')
          })
          .catch()
      }
    },
    deleteLead(id) {
      this.$http.delete(`/api/v1/bank-rate-tool/delete-lead/${id}`)
        .then(() => {
          if (this.ui.tabName === 'unassigned') { this.getUnassignedLeads() }
          if (this.ui.tabName === 'assigned') { this.getAssignedLeads() }
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.table {

  tbody {
    tr {
      td:not(:first-child) {
        padding-left: 0;
      }
    }
  }

  .status {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 90px;
    width: 100%;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 21px;
    text-align: center;
    border-radius: 8px;
    padding: 5px 0;
    height: 36px;

    &.pending {
      color: #266FE5;
      border: 1px solid rgba(38, 111, 229, 0.4);
      background-color: rgba(38, 111, 229, 0.05);
    }

    &.charged {
      color: #00AB34;
      border: 1px solid rgba(0, 171, 52, 0.4);
      background-color: rgba(0, 171, 52, 0.05);
    }

    &.error {
      color: #DB0C3E;
      border: 1px solid rgba(#DB0C3E, .4);
      background: rgba(#DB0C3E, 0.05);
    }
  }
}
</style>
